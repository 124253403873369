* {
  box-sizing: border-box;
}
:root {
  --primary: #ed3e42;
  --primaryGradient: linear-gradient(
    180deg,
    #f13c44 0%,
    #d4263a 51%,
    #bf2337 100%
  );
  --secondary: #ffba07;
  --white: #ffffff;
  --black: #000000;
  --primaryText: #626566;
  --borderBG: #dfdfdf;
  --mainFont: "PreciousSans";
  --red: #f20000;
}
body {
  font-family: var(--mainFont);
  margin: 0px;
  width: 100%;
  height: 100%;
  background: url("../images/background.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}
input,
select {
  font-family: var(--mainFont);
  font-size: 16px;
}
input:focus,
select:focus {
  outline: 0;
}
.cursorP {
  cursor: pointer !important;
}
.error {
  border: 1px solid var(--red) !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
div#root {
  width: 100%;
  height: 100vh;
  padding: 0px;
  overflow: auto;
}
div#root.scrollOut {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.btn {
  padding: 18px 30px;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}
.btn.btn--inFull {
  width: 100%;
}
.btn.btn--primaryG {
  background: var(--primaryGradient);
  color: var(--white);
}
.btn.btn--primaryOuter {
  background: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
}
.btn.btn--capsule {
  border-radius: 30px;
}
.btn.btn--sm {
  padding: 12px 30px;
}
.btn.btn--green {
  background: linear-gradient(0deg, #0eaf5d 0%, #0dae5e 100%, #0dae5e 100%);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  color: var(--white);
}
.btn.btn--yellowG {
  background: linear-gradient(180deg, #f1c40f 0%, #f39c12 100%);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  color: var(--white);
}
.btn.btn--icon {
  display: flex;
  align-items: center;
  float: left;
}
.fullViewPort {
  width: 100%;
  height: 100%;
  max-height: calc(100% - 40px);
  margin: 20px 0px;
  overflow: auto;
}
.flexCC {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexCS {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.flexCol {
  flex-direction: column;
}
.flexSS {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.w355 {
  width: 100%;
  max-width: 355px;
  padding: 30px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 30%);
  text-align: center;
  max-height: 100%;
  overflow: auto;
  background: var(--white);
  border-radius: 10px;
  border-bottom: 4px solid var(--secondary);
}
.fullPanel {
  width: 100%;
}
.logo {
  width: 235px;
  margin: 0 auto;
  display: inline-block;
}
.logoImage {
  width: 100%;
}
h1 {
  font-size: 24px;
  letter-spacing: 0.6px;
  line-height: 24px;
  text-align: center;
}
.mV5 {
  margin: 5px 0px;
}
.csForm {
  width: 100%;
}
.csForm__item {
  width: 100%;
  display: inline-block;
  margin: 15px 0px;
}
.csForm__item__input {
  width: 100%;
  height: 42px;
  border: 0px;
  font-weight: 700;
  border-bottom: 1px solid var(--borderBG);
  color: var(--primaryText);
}
.primaryTextLink {
  text-decoration: none;
  color: var(--primary);
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  font-weight: 700;
}
.container {
  width: 100%;
  max-width: 1410px;
  padding: 0px 15px;
  margin: 0 auto;
}
.pageNav {
  width: 100%;
  height: auto;
  min-height: 80px;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
}
.pageNav__left {
  width: 50%;
  display: flex;
  align-items: center;
}
.pageNav__left h2 {
  margin: 0px;
}
.fieldSelect {
  border: 0px;
  font-weight: 700;
  cursor: pointer;
  padding-right: 18px;
  font-size: 16px;
  margin-right: 20px;
  appearance: none;
  background: url("../images/chevron__bottom.png") no-repeat;
  background-size: 10px 6px;
  background-position: right;
}
.searchBlock {
  width: 350px;
  max-width: 100%;
  position: relative;
}
.searchBlock__input {
  height: 25px;
  width: 100%;
  border: none;
  padding: 2px 30px 2px 10px;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-weight: 700;
  color: var(--primaryText);
}
.hamburger {
  width: 30px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}
.hamburger label {
  width: 25px;
  height: 3px;
  background: var(--primary);
  border-radius: 5px;
  transition: 0.3s linear;
}
.hamburger:before,
.hamburger:after {
  content: "";
  width: 25px;
  height: 3px;
  background: var(--primary);
  position: absolute;
  left: 0;
  border-radius: 5px;
  transition: 0.3s linear;
}
.hamburger:before {
  top: 0px;
}
.hamburger:after {
  bottom: 0px;
}
.hamburger.active label {
  width: 15px;
  transition: 0.3s linear;
}
.hamburger.active {
  justify-content: flex-start;
}
.hamburger.active:before,
.hamburger.active:after {
  left: 0px;
  transition: 0.3s linear;
}
.hamburger.active:after {
  width: 10px;
  transition: 0.3s linear;
}

/* SIDEBAR */

.toggleSideBar {
  width: 100%;
  max-width: 250px;
  height: 100%;
  position: fixed;
  left: -260px;
  top: 0px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 40%);
  transition: 0.3s linear;
  z-index: 3;
}
.toggleSideBar__contents {
  height: auto;
  max-height: calc(100% - 120px);
  overflow: auto;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
.toggleSideBar__contents li {
  width: 100%;
  margin: 10px 0px;
  display: inline-block;
  font-weight: 600;
  cursor: pointer;
  padding-left: 0px;
  transition: 0.3s linear;
  position: relative;
  padding-right: 60px;
}
.toggleSideBar__contents li.active {
  color: var(--primary);
  font-weight: bold;
}
.toggleSideBarOut {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.toggleSideBarOut:before,
.toggleSideBarOut:after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--primary);
  position: absolute;
  left: 0px;
  top: calc(50% - 1px);
}
.toggleSideBarOut:before {
  transform: rotate(45deg);
}
.toggleSideBarOut:after {
  transform: rotate(-45deg);
}
.toggleSideBar.toggleSideBar--active {
  left: 0px;
  transition: 0.3s linear;
}
.toggleSideBar__logo {
  width: auto;
  height: 42px;
  margin-bottom: 40px;
}
.toggleSideBar__logo__in {
  height: 100%;
}

/* SIDEBAR END */

.iconIn {
  width: 16px;
  height: 16px;
  background: url("../images/sprite.svg") no-repeat;
  display: inline-block;
}
.iconIn.iconIn--search {
  background-position: -3px -2px;
}
.iconIn.iconIn--backArrow {
  width: 17px;
  height: 17px;
  background: url("../images/arrow-left.png") no-repeat;
  background-size: contain;
}
.iconIn.iconIn--doc {
  width: 13px;
  height: 13px;
  background-position: -71px -4px;
}
.iconIn.icon--toTop {
  width: 18px;
  height: 15px;
  background-position: -89px -3px;
}
.iconIn.iconIn--eye {
  width: 25px;
  height: 16px;
  background-position: -3px -79px;
}
.iconIn.iconIn--eye-primary {
  width: 25px;
  height: 16px;
  background-position: -30px -79px;
}
.iconIn.iconIn--export {
  width: 16px;
  height: 16px;
  background-position: -58px -79px;
}
.iconEdit {
  width: 20px;
  height: 20px;
  background: url("../images/edit.png") no-repeat;
  background-size: contain;
  display: inline-block;
  cursor: pointer;
}
.responsive {
  width: 100%;
}
.navLogo {
  width: auto;
  height: 40px;
  margin-right: 22px;
}
.navLogo__in {
  height: 100%;
  width: auto;
}
.searchBlock__icon {
  position: absolute;
  right: 8px;
  top: calc(50% - 8px);
  cursor: pointer;
}
.pageNav__right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pageNav__middle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.userBlock {
  display: flex;
  align-items: center;
  padding-right: 30px;
  position: relative;
  cursor: pointer;
}
.userBlock:after {
  content: "";
  width: 10px;
  height: 8px;
  position: absolute;
  right: 0px;
  top: calc(50% - 4px);
  background: url("../images/chevron__bottom.png") no-repeat;
  background-size: contain;
}
.userBlock__details {
  padding-right: 20px;
  text-align: right;
}
.userName {
  margin: 0px;
  font-size: 16px;
  letter-spacing: 0.4px;
}
.userRole {
  margin: 0px;
  font-size: 14px;
  letter-spacing: 0.35px;
  line-height: 18px;
  opacity: 0.5;
  font-weight: 600;
}
.userBlock__image {
  width: 45px;
  height: 45px;
}
.userBlock__image__in {
  width: 100%;
}
.roundBox {
  margin: 30px 0px;
  padding: 0px;
  background: var(--white);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 30%);
  border-radius: 10px;
  border-bottom: 4px solid var(--secondary);
  overflow: hidden;
}
.roundBox__head {
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: space-between;
}
.roundBox__head h2 {
  margin: 0px;
}
.roundBox__head h2.ellipseHead {
  margin: 0px 30px 0px 0px;
  width: 100%;
  word-break: break-all;
  max-width: 180px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ellipseName {
  word-break: break-all;
  max-width: 150px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.roundBox__filter {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.roundBox__filter__select {
  width: calc(50% - 10px);
  color: var(--primaryText);
  font-size: 14px;
  letter-spacing: 0.35px;
  padding-bottom: 18px;
  border: 0px;
  border-bottom: 1px solid var(--borderBG);
  padding-right: 18px;
  font-size: 16px;
  margin-right: 20px;
  appearance: none;
  background: url("../images/chevron__bottom.png") no-repeat;
  background-size: 10px 6px;
  background-position: right;
}
.roundBox__head .btn--primaryG {
  padding: 12px 35px;
}
.tableWrapper {
  width: 100%;
}
.tableWrapper.scrollable {
  overflow: auto;
}
.tableWrapper.scrollable.heighted {
  max-height: 450px;
}
.csTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 33px;
}
.csTable .csCheckbox {
  margin-right: 20px;
  top: 2px;
}
.csTable thead th {
  background: #f6f6f6;
  padding: 16px 35px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.35px;
  line-height: 18px;
  font-weight: 600;
  border-bottom: 1px solid var(--borderBG);
  position: sticky;
  top: 0;
  left: 0;
}
.csTable tbody td {
  padding: 16px 35px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.35px;
  line-height: 18px;
  font-weight: 600;
  border-bottom: 1px solid var(--borderBG);
  white-space: nowrap;
}
.csTable tbody tr.active td {
  background: rgba(240, 60, 60, 0.05);
}
.table__user__image {
  width: 35px;
  height: 35px;
}
.csCheckbox {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
}
.csCheckbox__input {
  width: 100%;
  height: 100%;
  margin: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 1px solid transparent;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.csCheckbox__placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  border: 1px solid var(--borderBG);
  background: var(--white);
  transition: 0.3s linear;
}
.csCheckbox__input:checked + .csCheckbox__placeholder {
  background: var(--primary);
  transition: 0.3s linear;
  border: 1px solid var(--primary);
}
.csCheckbox__placeholder:before {
  content: "";
  width: 9px;
  height: 8px;
  background: url("../images/sprite.svg") no-repeat -23px -6px;
  position: absolute;
  left: calc(50% - 4.5px);
  top: calc(50% - 4px);
  transition: 0.3s linear;
  transform: scale(0);
}
.csCheckbox__input:checked + .csCheckbox__placeholder:before {
  transition: 0.3s linear;
  transform: scale(1);
}
.gridWrapper {
  padding: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.gridEach {
  width: calc(25% - 30px);
  margin: 15px;
  border-radius: 10px;
  position: relative;
}
.gridInput {
  width: 100%;
  height: 100%;
  margin: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}
.gridInputPlaceholder {
  width: 25px;
  height: 25px;
  position: absolute;
  background: var(--primaryGradient);
  border-radius: 10px 0px 5px 0px;
  opacity: 0;
}
.gridInputPlaceholder:after {
  content: "";
  width: 9px;
  height: 8px;
  background: url("../images/sprite.svg") no-repeat -23px -6px;
  position: absolute;
  left: calc(50% - 4.5px);
  top: calc(50% - 4px);
}
.gridEachBlock {
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 30%);
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid transparent;
  width: 100%;
}
.gridEachImage {
  width: 100%;
  height: 190px;
  background: #efefef;
}
.gridEachImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gridInput:checked + .gridInputPlaceholder {
  opacity: 1;
}
.gridInput:checked + .gridInputPlaceholder + .gridEachBlock {
  border: 1px solid var(--primary);
}
.gridEachContent {
  padding: 10px 30px;
  display: inline-block;
}
.gridEachContentEach {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px;
  float: left;
}
.gridEachContentEach__label {
  width: 50%;
  font-size: 14px;
  letter-spacing: 0.35px;
  color: var(--black);
  opacity: 0.5;
  font-weight: 700;
}
.gridEachContentEach__text {
  width: 50%;
  margin: 0px;
  font-size: 14px;
  letter-spacing: 0.35px;
  color: var(--black);
  font-weight: bold;
}
.pageSidebar {
  width: 0px;
  height: 100vh;
  background: var(--white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  padding: 32px 0px;
  float: left;
  transition: 0.3s linear;
}
.pageSidebarOffset {
  width: 100%; /*calc(100% - 250px)*/
  float: right;
  height: 100vh;
  overflow: auto;
  padding: 0px 30px;
  transition: 0.3s linear;
}
.pageSidebar.show {
  width: 250px;
  padding: 32px;
}
.pageSidebar.show + .pageSidebarOffset {
  width: calc(100% - 250px);
}
.logoIn {
  display: inline-block;
  width: 100%;
}
.logo__image {
  width: 100%;
}
.sidebarList {
  margin: 80px 0px 0px 0px;
  list-style-type: none;
  padding: 0px;
}
.sidebarList li {
  margin: 10px 0px;
  display: inline-block;
  width: 100%;
}
.sidebarList__item {
  text-decoration: none;
  color: var(--black);
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 24px;
  font-weight: 700;
  transition: 0.3s linear;
}
.sidebarList__item--active {
  color: var(--primary);
  transition: 0.3s linear;
}
.pageNav.pageNav--flexCol {
  flex-direction: column;
  padding: 15px 30px;
}
.pageNav__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.iconAnchor {
  color: #1d1d1b;
  font-size: 18px;
  letter-spacing: 0.45px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.iconAnchor .iconIn {
  margin-right: 15px;
}
.pageNav__buttonWrapper .btn {
  font-weight: 600;
  margin-left: 20px;
}
.pageNav__bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.halfBlock {
  width: calc(50% - 15px);
}
.halfBlock .gridEachContent {
  padding: 0;
}
.halfBlock .gridEachContent {
  padding: 0;
}
.userImageBlock {
  width: 190px;
}
.userImageIn {
  width: 100%;
  height: 110px;
  background: #7f7f7f;
  border-radius: 10px;
}
.userImageBlock--offset {
  width: calc(100% - 190px);
  padding-left: 30px;
}
.halfBlock .gridEachContentEach {
  margin: 5px 0px;
}
.fullFlexBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.paddingBlock {
  padding: 30px;
  background: var(--white);
  margin: 30px 0px;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  border-bottom: 4px solid var(--secondary);
}
.pageNav + .paddingBlock,
.pageNav + .roundBox {
  min-height: calc(100vh - 142px);
}
.paddingBlock .gridEachContentEach__text {
  padding-left: 20px;
}
.w55p {
  width: calc(55% - 15px);
}
.w45p {
  width: calc(45% - 15px);
}
.w45p .gridEachContent {
  padding: 0;
}
.w45p .gridEachContentEach__label {
  width: 40%;
}
.w45p .gridEachContentEach__text {
  width: 60%;
}
.w45p .gridEachContentEach {
  margin: 5px 0px;
}
.videoFrame {
  width: 100%;
  height: 310px;
  background: #a4a4a4;
  border-radius: 10px;
  position: relative;
}
.videoFrame__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 30px;
  flex-direction: column;
}
.iconIn.iconIn--playIcon {
  width: 32px;
  height: 32px;
  background-position: -4px -23px;
}
.videoFrame__overlay .iconIn {
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  cursor: pointer;
}
.borderTBox {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid var(--borderBG);
}
.highlightBox {
  padding: 5px 20px;
  color: var(--white);
  background: var(--primaryGradient);
  border-radius: 10px;
  font-size: 14px;
  letter-spacing: 0.35px;
  line-height: 18px;
  display: inline-block;
  cursor: pointer;
}
.lgText {
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 30px;
  font-weight: 700;
}
.videoFrame__overlay h5 {
  font-size: 24px;
  letter-spacing: 0.6px;
  line-height: 31px;
  margin: 0px 0px 8px 0px;
  width: 100%;
  color: var(--white);
}
.flexCB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fullBlock {
  margin: 30px 0px;
}
.title {
  margin: 0px;
  font-size: 24px;
  letter-spacing: 0.6px;
  line-height: 31px;
}
.fullInBlock .gridEachContent {
  padding: 0px;
}
.highlightBox .iconIn {
  margin-right: 5px;
}
.card {
  border: 1px solid var(--borderBG);
  border-radius: 10px;
  padding: 20px 30px;
}
.card {
  border: 1px solid var(--borderBG);
  border-radius: 10px;
  padding: 20px 30px;
  min-height: 240px;
  margin-bottom: 30px;
}
.cardHead {
  margin: 0px;
  color: var(--primary);
  font-size: 18px;
  letter-spacing: 0.56px;
  line-height: 24px;
  margin-bottom: 8px;
}
.flexCE {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btn .iconIn {
  margin-right: 10px;
}
.mb30 {
  margin-bottom: 30px;
}

.p30 {
  padding: 30px;
}
.csForm__item .blockIn {
  margin-top: 5px;
}
.csRadio,
.csTextCheckbox {
  display: inline-block;
  margin: 7.5px 15px 7.5px 0px;
  position: relative;
  cursor: pointer;
}
.csRadio__input,
.csTextCheckbox__input {
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.csRadio__placeholder,
.csTextCheckbox__placeholder {
  padding: 10px 20px;
  border: 1px solid var(--borderBG);
  border-radius: 4px;
  font-weight: 700;
  color: var(--primaryText);
  font-size: 16px;
  transition: 0.3s linear;
  display: inline-block;
}
.csRadio__input:checked + .csRadio__placeholder,
.csTextCheckbox__input:checked + .csTextCheckbox__placeholder {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--white);
  transition: 0.3s linear;
}

/* MODAL */

.csModal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgb(0, 0, 0, 70%);
  display: none;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 10;
}
.csModal.csModal--active {
  display: flex;
}
.csModal__content {
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow: auto;
  background: var(--white);
  padding: 40px;
  border-radius: 12px;
  position: relative;
  animation: 0.2s zoomIn linear forwards;
}

@keyframes zoomIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}
.csModal--sm .csModal__content {
  max-width: 350px;
}
.csModal--md .csModal__content {
  max-width: 560px;
}
.csModal--lg .csModal__content {
  max-width: 900px;
}
.csModal__out {
  width: 17px;
  height: 17px;
  position: absolute;
  background: url("../images/sprite.svg") no-repeat -5px -98px;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.statusModal .csModal__content {
  padding: 25px 30px;
}
.statusModal h3 {
  margin: 0px 0px 20px 0px;
  font-size: 24px;
  letter-spacing: 0.6px;
  line-height: 31px;
}
.modalSelect {
  width: 100%;
  border: 0px;
  border: 0;
  border-bottom: 1px solid #dfdfdf;
  font-family: var(--mainFont);
  margin-bottom: 20px;
  font-size: 14px;
  letter-spacing: 0.35px;
  color: #626566;
  padding: 15px 20px 15px 0px;
  appearance: none;
  background: url("../images/chevron__bottom.png") no-repeat;
  background-size: 10px 6px;
  background-position: right;
}
.modalTextarea {
  width: 100%;
  padding: 0px;
  height: 85px;
  resize: none;
  border: 0;
  border-bottom: 1px solid #dfdfdf;
  font-family: var(--mainFont);
  font-size: 14px;
  letter-spacing: 0.35px;
  color: #626566;
}
.modalTextarea:focus {
  outline: 0;
}
.btnHolderFull {
  margin-top: 20px;
}
.btnHolderFull .btn {
  padding: 12px 30px;
  text-align: center;
  font-weight: 600;
}
/* .csModal__out:before,
.csModal__out:after {
  content: "";
  width: 17px;
  height: 17px;
  background: var(--white);
  position: absolute;
  left: calc(50% - 7px);
  top: calc(50% - 1px);
}
.csModal__out:before {
  transform: rotate(45deg);
}
.csModal__out:after {
  transform: rotate(-45deg);
} */
/* MODAL END */

.messageIn {
  font-weight: bold;
  color: var(--secondary);
  text-shadow: 0px 0px 0px #000000;
}
.buttonWrap .btn {
  margin: 5px;
}
.hasInnerList.activeIn a {
  color: var(--primary);
  text-decoration: none;
}
.hasInnerList a {
  color: #888;
  text-decoration: none;
}
.toggleSideBar__contents ul {
  padding-left: 20px;
  display: none;
}
.toggleSideBar__contents > li {
  position: relative;
}
.toggleSideBar__contents > li:after {
  content: "";
  width: 8px;
  height: 12px;
  display: inline-block;
  background: url("../images/sprite.svg") no-repeat -41px -22px;
  margin-left: 8px;
  transition: 0.3s linear;
  transform: rotate(0deg);
  position: absolute;
  right: 20px;
  top: calc(50% - 6px);
}
.toggleSideBar__contents > li.active:after {
  transition: 0.3s linear;
  transform: rotate(90deg);
}
.toggleSideBar__contents > li.closed:after {
  content: none !important;
}
.toggleSideBar__contents > li.active + ul {
  display: block;
}
.toggleSideBar__contents ul li:hover {
  color: var(--primary);
  padding-left: 10px;
  transition: 0.3s linear;
}
.relBlock {
  display: inline-block;
  position: relative;
}
.userOptionList {
  width: 140px;
  border-radius: 0 0 27.5px 27.5px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  /* border: 1px solid var(--primary); */
  position: absolute;
  left: calc(50% - 60px);
  /* top: 100%; */
  top: calc(100% + 15px);
  list-style-type: none;
  padding: 10px 0px;
  margin: 0px;
  overflow: hidden;
}
.userOptionList li {
  width: 100%;
  display: inline-block;
  padding: 5px 20px;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
  transition: 0.3s linear;
  background: var(--white);
  color: var(--black);
  cursor: pointer;
}
.userOptionList li:hover {
  transition: 0.3s linear;
  background: var(--primaryGradient);
  color: var(--white);
}

.w330 {
  width: 330px;
}
.w330Rem {
  width: calc(100% - 330px);
  padding: 0px 20px 0px 0px;
}
.partitionFlexBox {
  width: 100%;
  display: flex;
}
.subheader {
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 26px;
  margin: 0px 0px 20px 0px;
}
.partitionTileHolder {
  width: calc(100% + 20px);
  margin: 0px -10px;
  display: flex;
  flex-wrap: wrap;
}
.threeTile {
  width: calc((100% / 3) - 20px);
  margin: 10px;
}
.applicationTile {
  min-width: 240px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #dfdfdf;
}
.applicationTile__head {
  min-height: 80px;
  padding: 10px 20px;
  width: 100%;
  float: left;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
}
.applicationTile__head--greenG {
  background: linear-gradient(0deg, #0eaf5d 0%, #0dae5e 100%, #0dae5e 100%);
}
.applicationTile__head label {
  font-size: 14px;
  letter-spacing: 0.35px;
  margin: 0px 0px 5px 0px;
}
.applicationTile__head p {
  margin: 0px;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: bold;
}

.applicationTile__content {
  width: 100%;
  float: left;
  padding: 8px 20px;
}
.applicationTile__content__each {
  width: 100%;
  margin: 8px 0px;
  display: inline-block;
}
.applicationTile__content__each label {
  color: var(--primaryText);
  font-size: 14px;
  letter-spacing: 0.35px;
  margin: 0px 0px 5px 0px;
  width: 100%;
  display: inline-block;
}

.applicationTile__content__each p {
  font-size: 14px;
  letter-spacing: 0.35px;
  font-weight: bold;
  margin: 0px;
}
.applicationTile__head--redG {
  background: linear-gradient(180deg, #f13c44 0%, #d4263a 51%, #bf2337 100%);
}
.applicationTile__head--yellowG {
  background: linear-gradient(180deg, #f1c40f 0%, #f39c12 100%);
}
.radialBox {
  margin: 10px 0px;
  border: 1px solid #dfdfdf;
  padding: 5px 20px;
  display: inline-block;
  border-radius: 10px;
}
.radialBox__each {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  float: left;
}
.radialBox__each .halfBlock:nth-child(odd) {
  margin-right: 15px;
}

.radialBox__each .halfBlock:nth-child(even) {
  margin-left: 15px;
}
.radialBox__each__label {
  font-size: 15px;
  letter-spacing: 0.4px;
  color: var(--primaryText);
  font-weight: 500;
}
.radialBox__indicatorBlock {
  display: flex;
  align-items: center;
}

.radialBox__indicator {
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-right: 10px;
}
.radialBox__indicator__1 {
  background: linear-gradient(225deg, #32c5ff 0%, #b620e0 51.26%, #f7b500 100%);
}
.radialBox__indicator__2 {
  background: linear-gradient(180deg, #6dd400 0%, #f7b500 100%);
}

.radialBox__indicator__3 {
  background: linear-gradient(0deg, #50e256 0%, #0dae5e 100%);
}
.radialBox__indicator__4 {
  background: linear-gradient(180deg, #f1c40f 0%, #f39c12 100%);
}
.fullRoundGraph {
  width: 240px;
  height: 240px;
  border-radius: 120px;
  margin: 20px auto;
  border: 35px solid transparent;
  border-top-color: #32c5ff;
  border-right-color: #b620e0;
  border-bottom-color: #50e256;
  border-left-color: #f1c40f;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fullRoundGraph label {
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 20px;
  color: var(--primaryText);
  font-weight: 500;
  text-align: center;
}
.fullRoundGraph p {
  font-size: 40px;
  letter-spacing: 1px;
  line-height: 40px;
  font-weight: bold;
  margin: 0px;
}
.mapLine {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mapLine__in {
  width: 100%;
  height: 2px;
}
.mapLine__in--1 {
  background: linear-gradient(225deg, #32c5ff 0%, #b620e0 51.26%, #f7b500 100%);
}
.mapLine__in--2 {
  background: linear-gradient(180deg, #6dd400 0%, #f7b500 100%);
}
.mapLine__in--3 {
  background: linear-gradient(0deg, #50e256 0%, #0dae5e 100%);
}
.mapLine__in--4 {
  background: linear-gradient(180deg, #f1c40f 0%, #f39c12 100%);
}
.sortBox {
  display: flex;
  padding: 0px 30px;
  align-items: center;
  justify-content: space-between;
}
.sortBox .csForm__item {
  max-width: 150px;
}
.sortBox .buttonWrap {
  min-width: 285px;
}
.sortBox__check {
  margin: 5px 20px 5px 0px;
  display: flex;
  align-items: center;
}
.csCheckbox__text {
  margin-right: 6px;
  font-size: 14px;
  font-weight: 500;
}
/* .sortBox .csForm__item {
  margin-left: 30px;
} */
.sortBox .flexCS {
  flex-wrap: wrap;
}
.csModal__content .csModal {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(0 0 0 / 50%);
  display: flex;
  z-index: 2;
}
.csModal.iframeModal .csModal__content {
  height: 100%;
}
.csModal.iframeModal .fullBlock {
  height: calc(100% - 60px);
}
.csModal.iframeModal iframe {
  height: 100%;
}
.sortBox__check .csCheckbox {
  margin-right: 8px;
}
.roundBox__head.flexCS {
  justify-content: flex-start;
}
.roundBox__head.flexCS h2 {
  margin-right: 25px;
}
.react-datepicker__input-container input {
  padding: 10px;
  margin: 5px;
  border: 0;
  border-bottom: 1px solid var(--borderBG);
}
.multipleOptionBlock {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--primary);
  border-radius: 30px;
  padding: 5px 10px;
  /* overflow: hidden; */
  position: relative;
}
.multipleOptionBlock .csForm__item {
  margin: 0px;
  max-width: 120px;
}
.multipleOptionBlock .csForm__item .csForm__item__input {
  border: 0px;
  font-weight: 600;
  height: 25px;
}
.multipleOptionBlock .searchBlock__input {
  font-weight: 600;
}
.multipleOptionBlock .errorMsg {
  width: 100%;
  font-size: 13px;
  font-weight: 700;
  position: absolute;
  bottom: -20px;
  text-align: center;
  left: 0px;
  color: var(--primary);
  margin: 0px;
  background: white;
}
.tabHead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
}
.tabWrapper {
  padding: 0px 30px;
}
.tabHead__items {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px;
  background: #cccccc;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
  margin-right: 5px;
  transition: 0.3s linear;
}
.tabHead__items.active {
  color: var(--white);
  background: var(--primary);
  transition: 0.3s linear;
}
.tabPanel {
  display: none;
}
.tabPanel.active {
  display: block;
}
.tabHead__items:not(.active):hover {
  background: #757575;
  color: var(--white);
  transition: 0.3s linear;
}
.sortBox__check.csLabeled .csForm__item__label {
  width: 100%;
  margin-right: 10px;
}
.sortBox__check.csLabeled .csForm__item {
  min-width: 140px;
}
.capsuleBlock {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}
.sortText {
  padding: 10px 15px;
  display: inline-block;
  border-left: 1px solid #ccc;
  font-size: 12px;
  font-weight: 700;
  color: #bdbdbd;
  width: 100%;
}
.capsuleBlock__content:first-child .sortText {
  border: 0;
}
.sortText.active {
  color: #000;
}
.w200 {
  width: 100%;
  max-width: 200px;
}
.w200Rem {
  width: calc(100% - 200px);
  padding-left: 30px;
}
.colorBgBlock {
  padding: 15px;
  border-radius: 8px;
}
.colorBgBlock.colorBgBlock--red {
  background: var(--primary);
}
.roundBlockSubtext {
  margin: 0px 0px 10px 0px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.roundBlockValue {
  text-align: right;
  margin: 0px 0px 0px;
  font-size: 24px;
  line-height: 24px;
}
.colorBgBlock--red .roundBlockSubtext {
  color: var(--white);
}
.colorBgBlock--red .roundBlockValue {
  color: var(--white);
}
.capsuleBlock__content {
  padding: 15px;
  width: 50%;
  border-left: 1px solid #ccc;
}
.capsuleBlock__content:first-child {
  border: 0;
}
.threeGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.capsuleBox {
  border: 1px solid #ccc;
  border-radius: 8px;
}
.dashboardMain__item {
  margin: 25px 0px;
  width: 100%;
  display: inline-block;
}
.capsuleBox .capsuleBlock__content {
  width: 100%;
}
.fiveGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.sixGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.text--green {
  color: #478418;
}
.text--red {
  color: var(--red);
}
.capsuleBlock__content.noPadding {
  padding: 0;
  display: inline-block;
  border: 0;
}
.permissionTabel {width: 100%;}
.permissionTabel tr td, .permissionTabel tr th {text-align: left;padding: 15px 20px; border-bottom-width: 0.5px;}
.permissionTabel tr th {background: var(--white); z-index: 5;}
.permissionTabel tr th:first-child .csCheckbox, .permissionTabel tr td:first-child .csCheckbox {margin-right: 10px;}
.permissionTabel tr th:not(:first-child), .permissionTabel tr td:not(:first-child) {text-align: center !important;}
.permissionTabel tr th:not(:first-child) .csCheckbox, .permissionTabel tr td:not(:first-child) .csCheckbox { margin: 0px;}
.permissionTabel .btn--sm {
  padding: 5px 25px;
}
.csCheckbox__input[disabled] {pointer-events: none;}
.csCheckbox__input[disabled] + .csCheckbox__placeholder {background: #d7d7d7;}
.sortBox__check .react-datepicker-wrapper {max-width: 130px;}
.react-datepicker__input-container input {width: 100%;}


@media (max-width: 1200px) {
  .gridEach {
    width: calc(50% - 30px);
  }
  .pageNav__bottom {
    flex-direction: column;
  }
  .pageNav__bottom .halfBlock {
    width: 100%;
    margin: 20px 0px;
  }
}

@media (max-width: 1050px) {
  .sortBox {
    flex-direction: column;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .sixGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .sixGrid .capsuleBlock__content {
    border-bottom: 1px solid #cccccc;
  }
  .sixGrid .capsuleBlock__content:nth-child(4) {
    border-left: 0;
    border-bottom: 0;
  }
  .sixGrid .capsuleBlock__content:nth-child(5) {
    border-bottom: 0;
  }
  .sixGrid .capsuleBlock__content:nth-child(6) {
    border-bottom: 0;
  }
}

@media (max-width: 991px) {
  .fullFlexBox {
    flex-direction: column;
  }
  .userImageBlock--offset {
    width: 100%;
    padding-left: 0px;
    margin-top: 20px;
  }
  .w55p {
    width: 100%;
  }
  .w45p {
    width: 100%;
  }
  .halfBlock {
    width: 100%;
  }
  .pageNav__top {
    flex-direction: column;
    align-items: flex-start;
  }
  .pageNav__buttonWrapper {
    display: flex;
  }
  .pageNav__buttonWrapper .btn {
    margin: 10px;
  }
  .partitionFlexBox {
    flex-direction: column;
  }
  .w330Rem {
    width: 100%;
    margin: 15px 0px;
  }
  .w330 {
    width: 100%;
    margin: 15px 0px;
  }
  .searchBlock {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .pageNav {
    flex-direction: column;
    padding: 15px 30px;
  }
  .pageNav__left {
    width: 100%;
    justify-content: space-between;
    order: 1;
  }
  .pageNav__right {
    width: 100%;
    margin-bottom: 15px;
  }
  .roundBox__filter {
    width: 100%;
    margin-bottom: 25px;
  }
  .roundBox__head {
    flex-direction: column;
    padding: 30px 10px;
    align-items: flex-end;
  }
  .gridEach {
    width: calc(100% - 30px);
  }
  .pageSidebar,
  .pageSidebar.show {
    width: 100%;
    height: auto;
  }
  .pageSidebar .logoIn {
    max-width: 220px;
    margin: 0 auto;
    display: block;
  }
  .sidebarList {
    margin-top: 30px;
  }
  .pageSidebarOffset,
  .pageSidebar.show + .pageSidebarOffset {
    width: 100%;
    padding: 0px;
    height: auto;
  }
  .card .gridEachContentEach {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0px;
  }
  .card .gridEachContentEach .gridEachContentEach__label {
    width: 100%;
    margin-bottom: 5px;
  }
  .card .gridEachContentEach .gridEachContentEach__text {
    width: 100%;
    padding: 0px;
  }
  .flexCB {
    flex-direction: column;
    align-items: flex-start;
  }
  .title {
    margin-bottom: 5px;
  }
  .pageNav__buttonWrapper {
    flex-direction: column;
  }
  .pageNav__buttonWrapper .btn {
    margin: 10px 0px;
  }
  .iconAnchor {
    margin-bottom: 10px;
  }
  .userImageBlock {
    width: 100%;
  }
  .userImageIn {
    height: auto;
    min-height: 160px;
  }
  .pageNav__middle {
    margin-bottom: 15px;
  }
  .searchBlock {
    width: 100%;
  }
  .partitionTileHolder {
    align-items: center;
    justify-content: center;
  }
  .threeTile {
    width: 100%;
    margin: 15px 0px;
  }
  .tabHead__items {
    flex-shrink: 0;
  }
  .w200Rem {
    width: 100%;
    margin: 20px 0px 0px 0px;
    padding-left: 0px;
  }
  .sixGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .sixGrid .capsuleBlock__content {
    border-bottom: 1px solid #cccccc;
  }
  .sixGrid .capsuleBlock__content:nth-child(4) {
    border-left: 0;
    border-bottom: 0;
  }
  .sixGrid .capsuleBlock__content:nth-child(5) {
    border-bottom: 0;
  }
  .sixGrid .capsuleBlock__content:nth-child(6) {
    border-bottom: 0;
  }
}
